import React, { useState, useEffect } from 'react';
import { MdArrowForward, MdMail, MdSend, MdMenu, MdClose } from 'react-icons/md';
import { Link } from 'react-scroll';
import FeatureCard from '../../components/FeaturedCard';
import '../../styles/animations.css';
import Footer from '../../components/Footer';
import { LazyLoadImage } from "react-lazy-load-image-component";

const slides = [
  {
    title: 'WELCOME TO',
    subtitle: 'RED WATCH FIRE SECURITY SERVICES',
    description: 'Red Watch Security Service is a premier and bespoke waking watch service, founded by experienced career firefighters.'
  },
  {
    title: 'YOUR SAFETY, OUR PRIORITY',
    subtitle: 'EXPERT SECURITY SERVICES',
    description: 'Providing top-notch security solutions to keep you safe and secure at all times.'
  },
  {
    title: 'TRUSTED BY PROFESSIONALS',
    subtitle: 'FIRE & SECURITY SERVICES',
    description: 'Our team is dedicated to ensuring the highest level of safety and security for our clients.'
  }
];

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="bg-transparent text-white py-4 px-6 fixed w-full z-20 border- border-gray-700">
      <div className="container mx-auto flex justify-between items-center">
      <div className="flex items-center space-x-4">
  < img src="/images/newlogo.png" alt="Logo" className="h-28 md:h-40" />
  <span className="text-xl font-bold"></span>
</div>

        <div className="hidden md:flex space-x-6 font-bold border-solid">
          <Link
            to="about-section"
            smooth={true}
            duration={1000}
            className="cursor-pointer hover:text-red-500 hover:border-b-2 border-red-500"
          >
            About
          </Link>
          <Link
            to="services-section"
            smooth={true}
            duration={1000}
            className="cursor-pointer hover:text-red-500 hover:border-b-2 border-red-500"
          >
            Services
          </Link>
          <Link
            to="team-section"
            smooth={true}
            duration={1000}
            className="cursor-pointer hover:text-red-500 hover:border-b-2 border-red-500"
          >
            Our Team
          </Link>
          <Link
            to="contact-section"
            smooth={true}
            duration={1000}
            className="cursor-pointer hover:text-red-500 hover:border-b-2 border-red-500"
          >
            Contact
          </Link>
        </div>
        <div className="md:hidden">
          <button onClick={toggleMenu} className="focus:outline-none">
            {isMenuOpen ? <MdClose size={24} /> : <MdMenu size={24} />}
          </button>
        </div>
      </div>
      {isMenuOpen && (
        <div className="md:hidden flex flex-col space-y-2 mt-4 px-6 font-bold bg-red-500">
          <Link
            to="about-section"
            smooth={true}
            duration={1000}
            className="cursor-pointer hover:text-red-500 hover:border-b-2 border-red-500"
            onClick={toggleMenu}
          >
            About
          </Link>
          <Link
            to="services-section"
            smooth={true}
            duration={1000}
            className="cursor-pointer hover:text-red-500 hover:border-b-2 border-red-500"
            onClick={toggleMenu}
          >
            Services
          </Link>
          <Link
            to="team-section"
            smooth={true}
            duration={1000}
            className="cursor-pointer hover:text-red-500 hover:border-b-2 border-red-500"
            onClick={toggleMenu}
          >
            Our Team
          </Link>
          <Link
            to="contact-section"
            smooth={true}
            duration={1000}
            className="cursor-pointer hover:text-red-500 hover:border-b-2 border-red-500"
            onClick={toggleMenu}
          >
            Contact
          </Link>
        </div>
      )}
    </nav>
  );
};

const Hero = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a loading delay
    const timer = setTimeout(() => {
      setLoading(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-black">
        < img src="/images/newlogo.png" alt="Loading" className="h-48 spinner" />
      </div>
    );
  }

  const { title, subtitle, description } = slides[0];

  return (
    <>
      <Navbar />
      <div className="relative overflow-hidden h-screen">
        <div className="absolute inset-0 bg-cover bg-center md:h-screen" style={{ backgroundImage: `url('/images/home4.jpeg')` }}></div>
        <div className="absolute inset-0 bg-black opacity-70 pointer-events-none md:h-screen"></div>
        <div className="relative z-10 flex flex-col justify-center items-center h-full text-center px-4 md:px-10 lg:px-20">
          <div className="sliding-text max-w-4xl slide-in">
            <h1 className="text-3xl font-bold text-white mb-2 md:text-6xl md:mb-4">{title}</h1>
            <h2 className="text-2xl font-bold text-red-500 mb-2 md:text-4xl md:mb-4">{subtitle}</h2>
            <p className="text-white mb-4 md:mb-8 text-center">{description}</p>
            <div className="flex flex-col space-y-4 md:flex-row md:space-x-4 md:space-y-0 justify-center">
              <Link to="about-section" smooth={true} duration={1000} className="cursor-pointer">
                <button className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition duration-300 md:px-6 md:py-3">
                  READ MORE <MdArrowForward className="ml-2 inline" />
                </button>
              </Link>
              <Link to="contact-section" smooth={true} duration={1000} className="cursor-pointer">
                <button className="border-2 border-white text-white px-4 py-2 rounded-md hover:bg-white hover:text-red-500 transition duration-300 md:px-6 md:py-3">
                  CONTACT US <MdMail className="ml-2 inline" />
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div id="about-section" className="flex flex-col bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 text-white p-4 md:flex-row md:p-8 fade-in">
        <div className="w-full mb-4 md:w-1/2 md:mb-0 md:p-4">
          < img src="/images/home1-min.jpg" alt="Image" className="w-full h-auto rounded-lg shadow-lg" />
        </div>
        <div className="w-full md:w-1/2 md:p-4 flex flex-col justify-center">
          <h1 className="text-3xl font-bold mb-2 md:text-4xl md:mb-4">ABOUT <span className='border-b border-red-700 '> RED WATCH</span></h1>
          <h2 className="text-xl text-red-500 font-semibold mb-2 md:text-2xl md:mb-4">YOUR TRUSTED PARTNER IN FIRE SECURITY</h2>
          <p className="mb-2 md:mb-4">
            Red Watch Fire Security Services is a premier bespoke waking watch service, founded by experienced career firefighters. With years of experience managing high-profile, high-risk sites, our team identified critical gaps in the industry and formed Red Watch to deliver superior service that prioritizes safety over profit.
          </p>
          <div className="space-y-2 md:space-y-4">
            <p>
              <strong>Trust:</strong> Our waking watch officers are either active or former qualified local authority firefighters.
            </p>
            <p>
              <strong>Value:</strong> We provide comprehensive Fire Risk Assessments for every site at no cost to our clients. Residents also have the option to request free home fire safety assessments.
            </p>
            <p>
              <strong>Accountability:</strong> Every patrol is GPS-monitored, and our officers are equipped with the latest thermal imaging technology.
            </p>
            <p>
              <strong>Specificity:</strong> Each patrol is a dynamic risk assessment conducted by a trained fire patrol officer.
            </p>
            <p>
              <strong>Awareness:</strong> Our fire officers are not just a visible presence; they are trained to be acutely aware of potential risks.
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap justify-center space-x-4 p-4 md:p-8 fade-in" id="services-section">
        <div className="flex-shrink-0 w-full md:w-auto">
          <FeatureCard
            title="Expertise"
            description="Our team utilises fully qualified local authority firefighters who have extensive experience managing waking watch systems. Our staff are also made up of firefighters ex firefighters and military personnel with fire experience. As well as fully trained fire wardens. We all participate in dynamic on site training to keep our high standards of professionalism."
          />
        </div>
        <div className="flex-shrink-0 w-full md:w-auto">
          <FeatureCard
            title="Technology"
            description="We utilize advanced thermal imaging capabilities and offer drone surveillance through reputable partners."
          />
        </div>
        <div className="flex-shrink-0 w-full md:w-auto">
          <FeatureCard
            title="Training"
            description="Our staff undergo continuous training, surpassing statutory requirements to ensure the highest standards of safety."
          />
        </div>
        <div className="flex-shrink-0 w-full md:w-auto">
          <FeatureCard
            title="Collaboration"
            description="As a firefighter-run company, we maintain close relationships with local fire and rescue services, ensuring seamless cooperation and adherence to the latest fire safety protocols."
          />
        </div>
      </div>
      <div className="bg-gray-800 py-12">
        <div className="relative overflow-hidden">
          <div className="absolute inset-0 bg-cover bg-center opacity-25 w-full" style={{ backgroundImage: `url('/images/fire2.jpg')` }}></div>
          <div className="bg-gray-800 py-12 relative">
            <div className="px-4 sm:px-6 lg:px-8">
              <div className="lg:text-center">
                <h2 className="text-3xl font-extrabold text-white sm:text-4xl ">
                  FEATURES OF OUR <span className=' border-b border-red-700'>SERVICE</span>
                </h2>
              </div>
              <div className="mt-10">
                <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
                  <div className="relative">
                    <dt>
                      <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-red-500 text-white">
                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 1c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10S17.52 1 12 1zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-13h2v6h-2zm0 8h2v2h-2z"/>

                        </svg>
                      </div>
                      <p className="ml-16 text-lg leading-6 font-medium text-white">24/7 Vigilance</p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray-300">
                    Continuous patrols to ensure early detection of fire hazards.
                    </dd>
                  </div>
                  <div className="relative">
                    <dt>
                      <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-red-500 text-white">
                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 2L2 7l10 5 10-5-10-5z"/>

                        </svg>
                      </div>
                      <p className="ml-16 text-lg leading-6 font-medium text-white">Simultaneous Evacuation Plans</p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray-300">
                    Tailored evacuation strategies tested regularly, including Personal Emergency Evacuation Plans (PEEPs).
                    </dd>
                  </div>
                  <div className="relative">
                    <dt>
                      <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-red-500 text-white">
                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 21v-1a2 2 0 012-2h14a2 2 0 012 2v1a2 2 0 01-2 2h-1a2 2 0 01-2-2v-1a2 2 0 011-2h1zM3 7h14a2 2 0 110 4h-14a2 2 0 010-4zM3 15h14a2 2 0 110 4h-14a2 2 0 010-4zM3 19h14a2 2 0 110 4h-14a2 2 0 010-4z"/>

                        </svg>
                      </div>
                      <p className="ml-16 text-lg leading-6 font-medium text-white">Advanced Equipment</p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray-300">
                    Our officers use the latest thermal imaging technology and GPS tracking for accountability.

                    </dd>
                  </div>
                  <div className="relative">
                    <dt>
                      <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-red-500 text-white">
                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                      </div>
                      <p className="ml-16 text-lg leading-6 font-medium text-white">Resident Engagement:</p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray-300">
                    We offer free home fire risk checks and maintain open communication with a 24-hour hotline for residents’ concerns.
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="team-section" className="relative bg-cover bg-gradient-to-r from-blue-900 via-blue-800 to-blue-900 bg-center py-12">
    <div className="absolute inset-0 bg-black opacity-70"></div>
    <div className="relative z-10 flex flex-col items-center text-center px-4 md:px-10 lg:px-20">
        <h1 className="text-3xl font-bold text-white mb-2 md:text-5xl md:mb-4">MEET THE <span className='border-b border-red-700'>TEAM</span></h1>
        <p className="text-white mb-4 md:mb-8">Our dedicated professionals are here to provide you with the highest level of service.</p>
        <div className="flex flex-col space-y-8 md:space-y-0 md:flex-row md:space-x-8 items-center">
            <div className="bg-white rounded-lg shadow-lg p-8 max-w-md md:max-w-lg">
                <h3 className="text-xl font-bold text-red-500 mb-4">Meet John Haskell</h3>
                <p className="text-gray-600 text-justify">
                A distinguished veteran of the British fire and rescue service, who dedicated 29 years to saving lives and protecting communities. John's illustrious career was predominantly spent on the prestigious Red Watch, where his leadership and expertise skills 
                in high risk environments became honed. Specializing in both domestic and international rescue operations, he mastered the intricacies of building collapse responses and car extrication, becoming a trusted figure in the field of complex rescue missions. After retiring in 2019, John transitioned his vast knowledge and experience into the private sector. He has played a critical role in risk management for high-rise towers across London and the South Coast. Leading dedicated teams of fire wardens, John oversees crucial waking watch and assisted evacuation scenarios, ensuring safety and preparedness are maintained at the highest standards. The name "Red Watch" is a tribute to the place of work where he honed his skills and made a lasting impact, symbolizing his commitment to excellence and the indelible mark he left on the fire and rescue community.
                </p>
            </div>
            <div className="md:mx-8">
                <img src="/images/unnamed.jpg" alt="Middle Image" className="mx-auto w-full h-auto" />
            </div>
            <div className="bg-white rounded-lg shadow-lg p-8 max-w-md md:max-w-lg">
                <h3 className="text-xl font-bold text-red-500 mb-4">Meet Andy Humphrey</h3>
                <p className="text-gray-600 text-justify">
                    With almost thirty years’ experience in the media and communication industries, Andy is highly familiar with building trusted and dedicated teams. From working as an Executive Producer on popular television shows to delivering innovative campaigns for world-renowned brands, Andy is well versed in working to deadlines, generating great team spirit, meeting targets, and exceeding expectations. When you choose to work with Red Watch Fire Security Services, you can be confident that not only will your fire security needs be met, but they'll be done so with a positive and uplifting team of people who are both highly professional and excellent company.<br /> <br /> <br /><br /><br /><br /><br /><br />
                </p>
            </div>
        </div>
    </div>
</div>





      <div className="relative bg-gray-900 text-white py-20 px-4 sm:px-6 lg:px-8" id="contact-section">
        <div className="absolute inset-0 bg-cover bg-center opacity-10 w-full" style={{ backgroundImage: `url('/images/fewe.png')` }}></div>
        <div className="relative max-w-3xl mx-auto">
        <div className="lg:text-center">
                <p className="mt-2 text-3xl leading-8 font-bold tracking-tight text-white sm:text-4xl">GET IN TOUCH</p>
                <p className="mt-4 max-w-2xl text-xl text-gray-300 lg:mx-auto">We would love to hear from you! Whether you have a question about our services, pricing, or anything else, our team is ready to answer all your questions.</p>
              </div>
          <form className="mt-10 space-y-6">
            <div>
              <label htmlFor="name" className="block text-sm font-medium text-gray-300">Name</label>
              <div className="mt-1">
                <input id="name" name="name" type="text" required className="appearance-none  text-black block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm" />
              </div>
            </div>
            <div>
              <label htmlFor="email" className="block text-black text-sm font-medium text-gray-300">Email</label>
              <div className="mt-1">
                <input id="email" name="email" type="email" autoComplete="email" required className="appearance-none block text-black w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm" />
              </div>
            </div>
            <div>
              <label htmlFor="message" className="block text-sm font-medium text-black text-gray-300">Message</label>
              <div className="mt-1">
                <textarea id="message" name="message" rows="4" className="appearance-none block text-black w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"></textarea>
              </div>
            </div>
            <div className="flex items-center justify-between">
                          <button className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-yellow-600 transition duration-300" type="submit">
                            Send Message <MdSend className="ml-2 inline" />
                          </button>
                        </div>
          </form>
        </div>
        
      </div>
      
      <Footer/>
    </>
  );
};

export default Hero;





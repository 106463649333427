// src/App.js
import React from 'react';
import HomePage from './pages/HomePage/HomePage';
import BlogPage from './pages/BlogPage/BlogPage';
import ContactPage from './pages/ContactPage/ContactPage';


function App() {
  return (
    <div className="App">
      <HomePage />
      
    </div>
  );
}

export default App;

// FeatureCard.js

import React from 'react';
import { MdLocalFireDepartment, MdSecurity, MdSchool, MdPeople } from 'react-icons/md'; // Importing icons

const FeatureCard = ({ title, description }) => {
  // Mapping titles to corresponding icons
  const iconMap = {
    Expertise: <MdLocalFireDepartment />,
    Technology: <MdSecurity />,
    Training: <MdSchool />,
    Collaboration: <MdPeople />
  };

  return (
    <div className="max-w-md mx-auto w-96 h-80 bg-white shadow-lg rounded-lg overflow-hidden">
      <div className="p-6 flex flex-col justify-center items-center">
        <div className="text-4xl mb-4 text-red-500">{iconMap[title]}</div> {/* Displaying the icon */}
        <div className="font-bold text-xl mb-2">{title}</div>
        <p className="text-gray-700 text-base text-center">{description}</p>
      </div>
    </div>
  );
};

export default FeatureCard;

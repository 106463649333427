import React from 'react';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-gray-400 py-10">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0 px-4">
        <div className="flex flex-col items-center md:items-start space-y-4">
          <img src="/images/newlogo.png" alt="Logo" className="h-20" />
          <p className="text-center md:text-left">
            Red Watch Fire Security Service<br />
            Your trusted partner in safety and security
          </p>
        </div>
        <div className="flex space-x-6">
          <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white transition duration-300">
            <FaFacebookF />
          </a>
          <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white transition duration-300">
            <FaTwitter />
          </a>
          <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white transition duration-300">
            <FaInstagram />
          </a>
          <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white transition duration-300">
            <FaLinkedinIn />
          </a>
        </div>
        <div className="text-center md:text-right">
          <h2 className="text-xl font-bold text-white mb-2">Contact Us</h2>
          <p>
            Phone: 7920045325<br />
            Email: john@redwatchservices.com<br />
          </p>
        </div>
      </div>
      <div className="mt-8 border-t border-gray-700 pt-6 text-center text-gray-400">
        <p>&copy; {new Date().getFullYear()} Red Watch Fire Security Service.| Powered by LOUDESPACE | All rights reserved. </p>
      </div>
    </footer>
  );
};

export default Footer;
